import { z } from "zod"

/**
 * DISCLAIMER
 * This request will only contain values directly stored in the database
 * Any derived values will not be included. Check the database excel table to see what is what
 */

export const ZrDatenSchema = z.object({
    id: z.number(),
    Startdatum: z.string().nullish(),
    Enddatum: z.string().nullish(),
    Verbrauchte_Menge: z.number().nullish(),
    Warmwasserwertermittlung: z.string().nullish(),
    Verbrauchswert_kWh_Strom: z.number().nullish(),
    Abrechnungsdokument: z.string().nullish(),
})

export const EtDatenSchema = z.object({
    id: z.number(),
    Energietraeger_Verbrauch: z.string().nullish(),
    Sonstiger_Energietraeger_Verbrauch: z.string().nullish(),
    ZRDaten: z.array(ZrDatenSchema).nullish(),
})

export type IEtDaten = z.infer<typeof EtDatenSchema>

export const EtDatensSchema = z.array(EtDatenSchema)

export type IEtDatens = z.infer<typeof EtDatensSchema>
