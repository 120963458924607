import { Heading, Text } from "@components/atoms/typography"
import { ContentOffset } from "@components/molecules/content-offset"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import { useTitlebar } from "@hooks/useTitlebar"

export function Terms() {
    const projectId = useProjectIdParam()

    useTitlebar({
        title: "",
        mode: "back",
        backTo: `/authenticated/chat/${projectId ?? ""}`,
        showContextMenu: false,
    })

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-6">
                <div className="grid gap-1">
                    <Heading level="h2">AGB</Heading>
                    <Text variant="small" className="text-textLight">
                        Stand: 14.02.2024
                    </Text>
                </div>

                <section className="grid gap-2">
                    <Heading level="h4">1. Geltungsbereich</Heading>
                    <Text variant="body1" className="text-textLight">
                        Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für die Nutzung der Energieberater-App
                        (nachfolgend "App" genannt), die von der [Name des Anbieters] (nachfolgend "Anbieter" genannt)
                        angeboten wird. Die App ermöglicht es Nutzern, mit Energieberatern in Kontakt zu treten und
                        Energieberatungen zu buchen.
                    </Text>
                </section>

                <section className="grid gap-2">
                    <Heading level="h4">2. Vertragsabschluss</Heading>
                    <Text variant="body1" className="text-textLight">
                        2.1. Der Vertrag zwischen dem Nutzer und dem Anbieter kommt durch die Nutzung der App zustande.
                        Mit der Nutzung der App erklärt sich der Nutzer mit diesen AGB einverstanden.
                    </Text>
                    <Text variant="body1" className="text-textLight">
                        2.2. Ein weiterer wichtiger Punkt.
                    </Text>
                </section>

                <section className="grid gap-2">
                    <Heading level="h4">3. Leistungen des Anbieters</Heading>
                    <Text variant="body1" className="text-textLight">
                        4.1. Wenn Sie Verbraucher sind (also eine natürliche Person, die die Bestellung zu einem Zweck
                        abgibt, der weder Ihrer gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet werden
                        kann), steht Ihnen nach Maßgabe der gesetzlichen Bestimmungen ein Widerrufsrecht zu.
                    </Text>
                    <Text variant="body1" className="text-textLight">
                        4.2. Machen Sie als Verbraucher von Ihrem Widerrufsrecht nach Ziffer 4.1 Gebrauch, so haben Sie
                        die regelmäßigen Kosten der Rücksendung zu tragen.
                    </Text>
                    <Text variant="body1" className="text-textLight">
                        4.3. Im Übrigen gelten für das Widerrufsrecht die Regelungen, die im Einzelnen wiedergegeben
                        sind in der folgenden
                    </Text>
                </section>

                <section className="grid gap-2">
                    <Heading level="h4">4. Widerrufsrecht</Heading>
                    <Text variant="body1" className="text-textLight">
                        Der Vertrag zwischen dem Nutzer und dem Anbieter kommt durch die Nutzung der App zustande. Mit
                        der Nutzung der App erklärt sich der Nutzer mit diesen AGB einverstanden.
                    </Text>
                    <ul className="grid list-disc gap-2 pl-6 text-textLight">
                        <li>
                            <Text variant="body1" className="text-textLight">
                                Mit der Nutzung der App erklärt sich der Nutzer mit diesen AGB einverstanden.
                            </Text>
                        </li>
                        <li>
                            <Text variant="body1" className="text-textLight">
                                Machen Sie als Verbraucher von Ihrem Widerrufsrecht nach Ziffer 4.1 Gebrauch.
                            </Text>
                        </li>
                    </ul>
                </section>

                <section className="grid gap-2">
                    <Heading level="h4">5. Pflichten des Nutzers</Heading>
                    <Text variant="body1" className="text-textLight">
                        Der Anbieter haftet für Schäden, die dem Nutzer durch die Nutzung der App entstehen, nur bei
                        Vorsatz oder grober Fahrlässigkeit.
                    </Text>
                    <ol className="grid list-decimal gap-2 pl-6 text-textLight">
                        <li>
                            <Text variant="body1" className="text-textLight">
                                Der Nutzer verpflichtet sich, die App nur bestimmungsgemäß zu nutzen.
                            </Text>
                        </li>
                        <li>
                            <Text variant="body1" className="text-textLight">
                                Er darf die App nicht missbräuchlich nutzen oder in sonstiger Weise gegen diese AGB
                                verstoßen.
                            </Text>
                        </li>
                    </ol>
                </section>

                <section className="grid gap-2">
                    <Heading level="h4">6. Schlussbestimmungen</Heading>
                    <Text variant="body1" className="text-textLight">
                        Sollten einzelne Bestimmungen dieser AGB unwirksam sein, so berührt dies die Wirksamkeit der
                        übrigen Bestimmungen nicht.
                    </Text>
                </section>
            </section>
        </ContentOffset>
    )
}
