import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { GDatenSchema } from "./schema"

export function transform(gDaten: any) {
    return {
        id: gDaten.id,
        ...gDaten.attributes,
    }
}

type IUseGDaten = {
    enabled?: boolean
}

export function useGDaten(projectId: number | null, params?: IUseGDaten) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["g-datens", projectId],
        queryFn: async () => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/g-datens?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            const gDaten = handleParse(() => GDatenSchema.parse(response ? transform(response.data) : null))
            return gDaten
        },
        enabled: enabled && !!authHeader && !!projectId,
    })
}
