import { IMZeile } from "@api/mzeile/schema"
import { Button } from "@components/atoms/buttons"
import { InfoCard } from "@components/molecules/info-card"

type ModernizationCardProps = {
    mZeile: IMZeile
    onDelete: (id: number) => void
    onUpdate: (mZeile: IMZeile) => void
}

export function ModernizationCard(props: ModernizationCardProps) {
    const { mZeile, onDelete, onUpdate } = props
    const {
        Bauteil_Anlagenteil,
        Massnahmenbeschreibung,
        Modernisierungskombination,
        Amortisation,
        Spezifische_Kosten,
    } = mZeile

    return (
        <InfoCard
            footer={
                <>
                    <Button variant="warning" onClick={() => onDelete(mZeile.id)}>
                        <i className="ri-delete-bin-line"></i>
                        Delete
                    </Button>
                    <Button variant="secondary" onClick={() => onUpdate(mZeile)}>
                        <i className="ri-edit-2-fill"></i>
                        Edit
                    </Button>
                </>
            }
        >
            <InfoCard.InfoAttribute>Bauteil Anlagenteil</InfoCard.InfoAttribute>
            <InfoCard.InfoContent>{Bauteil_Anlagenteil}</InfoCard.InfoContent>

            <InfoCard.InfoAttribute>Massnahmenbeschreibung</InfoCard.InfoAttribute>
            <InfoCard.InfoContent>{Massnahmenbeschreibung}</InfoCard.InfoContent>

            <InfoCard.InfoAttribute>Modernisierungskombination</InfoCard.InfoAttribute>
            <InfoCard.InfoContent>{Modernisierungskombination}</InfoCard.InfoContent>

            <InfoCard.InfoAttribute>Amortisation</InfoCard.InfoAttribute>
            <InfoCard.InfoContent>{Amortisation}</InfoCard.InfoContent>

            <InfoCard.InfoAttribute>Spezifische Kosten</InfoCard.InfoAttribute>
            <InfoCard.InfoContent>{Spezifische_Kosten}</InfoCard.InfoContent>
        </InfoCard>
    )
}
