import { apiUrl, handleFetch } from "@api/apiConfig"
import { ProductId, ProductSchema } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export function transform(data: any) {
    return data
}

export function useProduct(id: ProductId) {
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["product", id],
        queryFn: async () => {
            const product = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/product?id=${id}`, {
                    headers: {
                        ...authHeader,
                    },
                })
                if (response.status === 404) {
                    return null
                }
                return response
            })
            if (!product) {
                throw new Error("Produkt nicht gefunden")
            }

            return ProductSchema.parse(transform(product))
        },
        enabled: !!authHeader,
    })
}
