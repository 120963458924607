import { z } from "zod"

/**
 * DISCLAIMER
 * This request will only contain values directly stored in the database
 * Any derived values will not be included. Check the database excel table to see what is what
 */

export const GDatenSchema = z
    .object({
        id: z.number(),
        Projektbezeichnung_Aussteller: z.string().nullish(),
        Gebaeudeadresse_Strasse_Nr: z.string().nullish(),
        Gebaeudeadresse_Postleitzahl: z.string().nullish(),
        Gebaeudeadresse_Ort: z.string().nullish(),
        Ausstellervorname: z.string().nullish(),
        Ausstellername: z.string().nullish(),
        Aussteller_Bezeichnung: z.string().nullish(),
        Aussteller_Strasse_Nr: z.string().nullish(),
        Aussteller_PLZ: z.string().nullish(),
        Aussteller_Ort: z.string().nullish(),
        Zusatzinfos_beigefuegt: z.boolean().nullish(),
        Angaben_erhaeltlich: z.string().nullish(),
        Ergaenzdende_Erlaeuterungen: z.string().nullish(),
        Ergaenzdende_Erlaeuterungen_EE: z.string().nullish(),
        Gebaeude_Foto: z.string().nullish(),
        Fenster_Foto: z.string().nullish(),
        Dachboden_Foto: z.string().nullish(),
        Keller_Foto: z.string().nullish(),
        Heizraum_Foto: z.string().nullish(),
        Typenschild_Foto: z.string().nullish(),
        Schornsteinfeger_Dokument: z.string().nullish(),
    })
    .nullable()

export type IGDaten = z.infer<typeof GDatenSchema>
