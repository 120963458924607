import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useRemoveCover(projectId: number | null) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !projectId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/projects/${projectId}`, {
                    method: "put",
                    body: JSON.stringify({
                        data: {
                            cover: {
                                set: [],
                            },
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gelöscht")
            await queryClient.invalidateQueries({
                queryKey: ["project", projectId],
            })
        },
    })
}
