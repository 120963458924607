import { useDebugConsumption } from "@api/certificate/useDebugConsumption"
import { useDebugDemand } from "@api/certificate/useDebugDemand"
import { Button } from "@components/atoms/buttons"
import { GradientBackground } from "@components/atoms/gradient"
import { Input } from "@components/atoms/input"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { ContentOffset } from "@components/molecules/content-offset.tsx"
import { Scrollable } from "@components/molecules/scrollable"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import * as Form from "@radix-ui/react-form"
import hljs from "highlight.js"
import React from "react"
import { toast } from "sonner"

type DataSectionProps = {
    heading: string
    content: string
    language: "json" | "xml"
}

function DataSection(props: DataSectionProps) {
    const { content, language, heading } = props

    const copy = async () => {
        await navigator.clipboard.writeText(props.content)
        toast.success("In Zwischenablage kopiert!")
    }

    const highlight = () => {
        return hljs.highlight(content, { language }).value
    }

    return (
        <GradientBackground
            variant="light"
            radius="small"
            className="grid grid-cols-[minmax(0,1fr)] gap-4 bg-primary/15 p-4"
        >
            <div className="flex gap-4">
                <Heading level="h4">{heading}</Heading>{" "}
                <Button variant="freeform" className="flex gap-2" onClick={copy}>
                    <i className="ri-clipboard-line" title="Copy-to-Clipboard"></i>
                </Button>
            </div>
            <Scrollable className="max-h-96">
                <pre
                    className="w-100 whitespace-pre-wrap text-tiny"
                    dangerouslySetInnerHTML={{ __html: highlight() }}
                ></pre>
            </Scrollable>
        </GradientBackground>
    )
}

export function ProjectDebug() {
    const formRef = React.useRef<HTMLFormElement | null>(null)
    const [projectId, setProjectId] = React.useState<number | undefined>(undefined)

    const {
        data: consumptionDebug,
        isLoading: consumptionLoading,
        refetch: refetchConsumption,
    } = useDebugConsumption(projectId ?? null, {
        enabled: false,
    })

    const {
        data: demandDebug,
        isLoading: demandLoading,
        refetch: refetchDemand,
    } = useDebugDemand(projectId ?? null, {
        enabled: false,
    })

    useTitlebar({
        title: "Projekt Debugger",
        mode: "back",
        backTo: `/authenticated/chat`,
        showContextMenu: false,
    })

    const demand = React.useMemo(() => {
        const formattedInput = JSON.stringify(demandDebug?.databaseInput ?? "", null, 4)
        const formattedKernelOutput = JSON.stringify(demandDebug?.kernelOutput ?? "", null, 4)
        const xml = demandDebug?.demand ?? ""

        return {
            formattedInput,
            formattedKernelOutput,
            xml,
        }
    }, [demandDebug])

    const consumption = React.useMemo(() => {
        const formattedInput = JSON.stringify(consumptionDebug?.databaseInput ?? "", null, 4)
        const xml = consumptionDebug?.consumption ?? ""

        return {
            formattedInput,
            xml,
        }
    }, [consumptionDebug])

    const onDemand = async () => {
        if (!formRef.current?.checkValidity()) {
            return
        }

        toast.promise(refetchDemand(), {
            loading: "Lade Bedarfsausweisdaten...",
            success: "Bedarfsdaten geladen!",
        })
    }

    const onConsumption = async () => {
        if (!formRef.current?.checkValidity()) {
            return
        }

        toast.promise(refetchConsumption(), {
            loading: "Lade Verbrauchsausweisdaten...",
            success: "Verbrauchsdaten geladen!",
        })
    }

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid w-full content-start gap-10 p-6">
                <section className="grid max-w-xl gap-2">
                    <Heading level="h2">Projekt Debugger</Heading>
                    <Text variant="body2" className=" text-textLight">
                        Hier kannst du dir die relevanten Daten zur Zertifikatgenerierung eines Projekts genau ansehen.
                    </Text>

                    <Form.Root onSubmit={(event) => event.preventDefault()} ref={formRef}>
                        <Input
                            name="project_id_to_test"
                            label="Project ID"
                            onInput={(event) => {
                                if (formRef.current?.checkValidity()) {
                                    setProjectId(Number(event.currentTarget.value))
                                }
                            }}
                            messages={[
                                {
                                    content: "Nur Integer-Zahlen erlaubt",
                                    match: (value) => isNaN(Number(value)),
                                },
                            ]}
                        />
                    </Form.Root>

                    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                        <Button
                            variant="secondary"
                            onClick={onConsumption}
                            loading={consumptionLoading}
                            disabled={consumptionLoading}
                        >
                            Generate Verbrauchsausweis
                        </Button>
                        <Button variant="secondary" onClick={onDemand} loading={demandLoading} disabled={demandLoading}>
                            Generate Bedarfsausweis
                        </Button>
                    </div>
                </section>

                {consumptionDebug && (
                    <section className="grid gap-4">
                        <Heading level="h2">Verbrauchsausweis Daten</Heading>
                        <DataSection heading="Databank Input" content={consumption.formattedInput} language="json" />
                        <DataSection heading="Finale XML" content={consumption.xml} language="xml" />
                    </section>
                )}

                {demandDebug && (
                    <section className="grid gap-4">
                        <Heading level="h2">Bedarfsausweis Daten</Heading>
                        <DataSection heading="Databank Input" content={demand.formattedInput} language="json" />
                        <DataSection heading="Kernel Output" content={demand.formattedKernelOutput} language="json" />
                        <DataSection heading="Finale XML" content={demand.xml} language="xml" />
                    </section>
                )}
            </section>
        </ContentOffset>
    )
}
