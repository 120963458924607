import React from "react"

export function useSize<T extends HTMLElement>() {
    const ref = React.useRef<T | null>(null)
    const [size, setSize] = React.useState<number[]>([0, 0])

    React.useLayoutEffect(() => {
        if (!ref.current) {
            return
        }

        const observer = new ResizeObserver(() => {
            if (ref.current) {
                setSize([ref.current.offsetWidth, ref.current.offsetHeight])
            }
        })

        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    return {
        ref,
        size,
    }
}
