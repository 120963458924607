import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { EnergyCertificateResponse, transform } from "@api/certificate/schema"
import { EnergyCertificateType } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

type IUseCertificate = {
    enabled?: boolean
}

export function useCertificate(projectId: number | null, type: EnergyCertificateType, params?: IUseCertificate) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["certificate", projectId, type],
        queryFn: async () => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/energy-certificate/for?projectId=${projectId}&type=${type}`, {
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            const parsed = handleParse(() => EnergyCertificateResponse.parse(response.data))
            return transform(parsed)
        },
        refetchInterval: (query) => {
            const { data } = query.state
            const isError = data?.draft?.error || data?.final?.error
            const shouldRefetch = data && !data.draft && !isError
            return shouldRefetch ? 5000 : false
        },
        enabled: !!enabled && !!authHeader && !!projectId,
    })
}
