import { cls } from "@utils"
import { ComponentProps } from "react"

type InfoCardProps = React.PropsWithChildren<ComponentProps<"div">> & {
    footer?: React.ReactElement
}

export function InfoCard(props: InfoCardProps) {
    const { children, footer, ...divProps } = props
    return (
        <div {...divProps} className={cls("grid gap-4 rounded-lg bg-primaryAccent3/10 p-4", divProps.className)}>
            <div className="grid gap-x-4 gap-y-0 sm:grid-cols-[auto,1fr]">{children}</div>
            {footer && <div className="col-span-2 flex gap-2">{footer}</div>}
        </div>
    )
}

InfoCard.InfoAttribute = (props: React.PropsWithChildren<ComponentProps<"span">>) => {
    const { children, ...spanProps } = props
    return (
        <span {...spanProps} className={cls("text-textLight", spanProps.className)}>
            {children}
        </span>
    )
}

InfoCard.InfoContent = (props: React.PropsWithChildren<ComponentProps<"span">>) => {
    const { children, ...spanProps } = props
    return (
        <span
            {...spanProps}
            className={cls("overflow-hidden text-ellipsis whitespace-nowrap text-text", spanProps.className)}
        >
            {children}
        </span>
    )
}
