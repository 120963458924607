import { useCreateCertificate } from "@api/certificate/useCreateCertificate"
import { useDraft } from "@api/certificate/useDraft"
import { useFinal } from "@api/certificate/useFinal"
import { useLastSentChatId } from "@api/chat/useLastSentChatId"
import { Button, LinkButton } from "@components/atoms/buttons"
import { TextAreaInput } from "@components/atoms/input"
import { ContentOffset } from "@components/molecules/content-offset"
import { Scrollable } from "@components/molecules/scrollable"
import { Reveal } from "@components/organisms/reveal"
import { EnergyCertificateType, EnergyCertificateVariant } from "@energuide/shared"
import { useDraftIdParam } from "@hooks/useDraftIdParam"
import { useTitlebar } from "@hooks/useTitlebar"
import * as Form from "@radix-ui/react-form"
import React from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { ReviewCard } from "./review-card"

export function ReviewConfirmation() {
    const draftId = useDraftIdParam()
    const { data: draft } = useDraft(draftId)
    const { data: lastSentChatIdData } = useLastSentChatId(draft?.project?.id ?? 0)
    const [comment, setComment] = React.useState("")
    const { mutateAsync: createFinal } = useCreateCertificate(
        draft?.project?.id ?? 0,
        draft?.type ?? EnergyCertificateType.Demand,
        lastSentChatIdData?.last_sent_message_chat_id ?? 0,
        EnergyCertificateVariant.Final,
        comment
    )

    const { data: final } = useFinal(draftId)
    const [creating, setCreating] = React.useState(false)
    const navigate = useNavigate()

    useTitlebar({
        title: "Final",
        mode: "back",
        backTo: `/authenticated/review/${draftId}`,
        showContextMenu: false,
        showSearchIcon: false,
    })

    React.useEffect(() => {
        if (!final) {
            return
        }

        setComment(final.comment ?? "")

        // there was an error while creating the final certificate
        if (final.final?.error) {
            setCreating(false)
            // go back to reviews if certificate was created
        } else if (final.final?.src) {
            setCreating(false)
            toast.success("Finales Zertifikat erstellt!")
            navigate("/authenticated/reviews")
        }
    }, [final, navigate])

    const onCommentChanged: React.FormEventHandler<HTMLTextAreaElement> = (event) => {
        const textarea = event.target as HTMLTextAreaElement
        setComment(textarea.value)
    }

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        try {
            setCreating(true)
            await createFinal()
        } catch {
            setCreating(false)
        }
    }

    if (!draft) {
        return null
    }

    return (
        <ContentOffset
            safeAreas={false}
            offsetAppbar={true}
            className="grid h-dvh grid-rows-[minmax(0,1fr)_auto] overflow-hidden"
        >
            <Scrollable>
                <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-4">
                    <ReviewCard certificate={draft} />

                    <Form.Root id="review-form" onSubmit={onSubmit}>
                        <TextAreaInput
                            name="comment"
                            minRows={5}
                            label="Kommentar"
                            value={comment}
                            onInput={onCommentChanged}
                        />
                    </Form.Root>

                    {final?.final?.error && (
                        <Reveal title="Fehler Log">
                            <pre className="overflow-x-scroll text-tiny">{final?.final?.error ?? ""}</pre>
                        </Reveal>
                    )}
                </section>
            </Scrollable>

            <div className="mx-auto grid w-full max-w-screen-md content-start gap-2 p-4 sm:grid-cols-2">
                <LinkButton variant="warning" href={`/authenticated/review/${draftId}`}>
                    Abbrechen
                </LinkButton>
                <Button variant="primary" form="review-form" type="submit" disabled={creating} loading={creating}>
                    Akzeptieren
                </Button>
            </div>
        </ContentOffset>
    )
}
