import { BackendError } from "@api/apiConfig"
import { useCreateCertificate } from "@api/certificate/useCreateCertificate"
import { Button } from "@components/atoms/buttons"
import { EnergyCertificateType, EnergyCertificateVariant } from "@energuide/shared"
import { useState } from "react"
import { toast } from "sonner"

interface Props {
    onNext: () => void
    projectId: number
    lastSentChatId: number
    type: EnergyCertificateType
    isCreating?: boolean
}

export function CertificateCreatePreview(props: Props) {
    const { onNext, projectId, lastSentChatId, type } = props

    const [isCreating, setIsCreating] = useState(false)
    const { mutateAsync } = useCreateCertificate(projectId, type, lastSentChatId, EnergyCertificateVariant.Draft)

    const createCertificatePreview = async () => {
        try {
            setIsCreating(true)
            await mutateAsync()
            onNext()
        } catch (error: any) {
            console.log("error: ", error)
            if (error instanceof BackendError) {
                toast.error(error.message)
            } else {
                toast.error("Fehler beim Erstellen der Vorschau")
            }
        } finally {
            setIsCreating(false)
        }
    }

    return (
        <Button
            loading={isCreating || props.isCreating}
            disabled={isCreating || props.isCreating}
            variant="primary"
            onClick={createCertificatePreview}
        >
            Vorschau erstellen
        </Button>
    )
}
