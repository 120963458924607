import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { EtDatensSchema } from "./schema"

export function transformZrDaten(zrDaten: any) {
    return {
        id: zrDaten.id,
        ...zrDaten.attributes,
    }
}

export function transform(etDaten: any) {
    return {
        id: etDaten.id,
        ...etDaten.attributes,
        ZRDaten: etDaten.attributes.ZRDaten.data?.map(transformZrDaten) ?? [],
    }
}

type IUseEtDaten = {
    enabled?: boolean
}

export function useEtDaten(projectId: number | null, params?: IUseEtDaten) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["et-datens", projectId],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/et-datens?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const etDaten = handleParse(() => EtDatensSchema.parse(data.map(transform)))
            return etDaten
        },
        enabled: enabled && !!authHeader && !!projectId,
    })
}
