import { Button } from "@components/atoms/buttons"
import { GradientBorder } from "@components/atoms/gradient"
import { useAppState } from "@hooks/useState"
import * as Form from "@radix-ui/react-form"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import Mark from "mark.js"
import React from "react"

type SearchInputProps = {
    className?: string
    onSearch: (term: string) => HTMLElement[]
}

export function SearchInput(props: SearchInputProps) {
    const searchOpen = useAppState((state) => state.searchOpen)
    const setSearchOpen = useAppState((state) => state.setSearchOpen)
    const [, appbarHeight] = useAppState((state) => state.appbarSize)

    const { className, onSearch } = props
    const [elements, setElements] = React.useState<HTMLElement[]>([])
    const [current, setCurrent] = React.useState<number>(0)
    const [term, setTerm] = React.useState<string>("")

    const onInput: React.FormEventHandler<HTMLInputElement> = (event) => {
        const input = event.target as HTMLInputElement
        setTerm(input.value)
        setElements(onSearch(input.value))
    }

    const onClose = React.useCallback(() => {
        setSearchOpen(false)
        setTerm("")
    }, [setSearchOpen])

    // update DOM elements
    React.useEffect(() => {
        setElements(onSearch(term))
    }, [term, onSearch])

    //Cleanup after unmount
    React.useEffect(() => {
        return () => onClose()
    }, [onClose])

    // highlight text in current element
    React.useEffect(() => {
        if (elements.length === 0) {
            return
        }

        const element = elements?.[current]
        if (!element) {
            setCurrent(0)
            return
        }

        const mark = new Mark(element)
        mark.mark(term, {
            className: "mark bg-secondary rounded-sm outline-4 outline outline-secondary",
        })

        element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        })

        return () => {
            mark.unmark()
        }
    }, [current, elements, term])

    const next = () => {
        const nextIdx = current + 1
        if (nextIdx >= elements.length) {
            setCurrent(0)
        } else {
            setCurrent(nextIdx)
        }
    }

    const prev = () => {
        const prevIdx = current - 1
        if (prevIdx < 0) {
            setCurrent(elements.length - 1)
        } else {
            setCurrent(prevIdx)
        }
    }

    const onEnter: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        next()
    }

    const onKeyDown: React.KeyboardEventHandler<HTMLFormElement> = (event) => {
        if (event.key === "Escape") {
            onClose()
        }
    }

    return (
        <AnimatePresence>
            {searchOpen && (
                <motion.div
                    className="absolute z-10 w-full py-2"
                    style={{ top: `${appbarHeight}px` }}
                    initial={{ y: "-25%", opacity: 0 }}
                    animate={{ y: "0%", opacity: 1 }}
                    exit={{ y: "-25%", opacity: 0 }}
                    transition={{ duration: 0.1 }}
                >
                    <Form.Root
                        className={cls("grid justify-items-center px-6 *:w-full *:sm:w-[50ch]", className)}
                        onSubmit={onEnter}
                        onKeyDown={onKeyDown}
                    >
                        <GradientBorder variant="dark" radius="full">
                            <Form.Field
                                name="search"
                                className="grid grid-cols-[auto,1fr,auto] gap-4 rounded-full bg-background py-2 pl-2 pr-4 text-primaryAccent2 shadow-xl outline
                                    outline-1 outline-secondary/50"
                            >
                                <div className="grid h-9 w-9 place-items-center rounded-full bg-secondary text-backgroundStrong">
                                    <i className="ri-chat-search-line text-[1.5rem]"></i>
                                </div>
                                <Form.Control asChild className="min-w-0" autoFocus>
                                    <input
                                        autoCapitalize="off"
                                        autoCorrect="off"
                                        onInput={onInput}
                                        className="bg-transparent"
                                    />
                                </Form.Control>
                                <div className="flex items-center gap-1">
                                    {elements.length !== 0 && (
                                        <>
                                            <span
                                                className="whitespace-nowrap text-tiny tabular-nums"
                                                data-testid="search-count"
                                            >
                                                {current + 1} / {elements.length}
                                            </span>
                                            <Button variant="freeform" type="button" onClick={prev}>
                                                <i className="ri-arrow-drop-up-line text-[1.5rem]"></i>
                                            </Button>
                                            <Button variant="freeform" type="button" onClick={next}>
                                                <i className="ri-arrow-drop-down-line text-[1.5rem]"></i>
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        variant="freeform"
                                        type="button"
                                        onClick={onClose}
                                        data-testid="search-close-button"
                                    >
                                        <i className="ri-close-line text-[1.5rem]"></i>
                                    </Button>
                                </div>
                            </Form.Field>
                        </GradientBorder>
                    </Form.Root>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
