import { useCertificate } from "@api/certificate/useCertificate"
import { useGDaten } from "@api/g-daten/useGDaten"
import { useKpis } from "@api/kpis/useKpis"
import { useProduct } from "@api/product/useProduct"
import { useProject } from "@api/projects/useProject.ts"
import { GradientBackground } from "@components/atoms/gradient"
import { Image } from "@components/atoms/image.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { BigStat } from "@components/molecules/big-stat.tsx"
import { CertificateGenerationBox } from "@components/molecules/certificate-gen-box"
import { Currency, EnergyCertificateType, ProductId } from "@energuide/shared"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { useMemo } from "react"

export function ProjectOverview() {
    const projectId = useProjectIdParam()
    const { data } = useProject(projectId)
    const { name = "---", cover } = data ?? {}
    const { data: gDaten } = useGDaten(projectId)
    const { data: certificate } = useCertificate(projectId, EnergyCertificateType.Demand)
    const { data: kpis } = useKpis(projectId, {
        // only run the KPIs query if a certificate was already created
        enabled: !!certificate && !(certificate.draft?.error || certificate.final?.error),
    })

    const demandProduct = useProduct(ProductId.DemandCertificate)
    const demandProductPrice = useMemo<Currency | null>(() => {
        if (
            demandProduct.data &&
            typeof demandProduct.data.price === "number" &&
            Number.isInteger(demandProduct.data.price * 100)
        ) {
            try {
                return new Currency("EUR", "Euro", "€", demandProduct.data.price * 100)
            } catch (e) {
                console.error("Error while creating currency object", e)
            }
        }
        return null
    }, [demandProduct])

    const consumptionProduct = useProduct(ProductId.ConsumptionCertificate)
    const consumptionProductPrice = useMemo<Currency | null>(() => {
        if (
            consumptionProduct.data &&
            typeof consumptionProduct.data.price === "number" &&
            Number.isInteger(consumptionProduct.data.price * 100)
        ) {
            try {
                return new Currency("EUR", "Euro", "€", consumptionProduct.data.price * 100)
            } catch (e) {
                console.error("Error while creating currency object", e)
            }
        }
        return null
    }, [consumptionProduct])

    useTitlebar({
        title: name ?? "",
        mode: "back",
        backTo: `/authenticated/chat/${projectId ?? ""}`,
        showContextMenu: true,
        contextMenuProps: {
            showBuildingPass: true,
            showEnergyCertificates: true,
            showProjectSettings: true,
        },
        projectId,
    })

    return (
        <section className="mx-auto grid max-w-screen-md content-start gap-10 pb-4">
            <div className="relative aspect-square object-cover lg:aspect-video">
                <Image
                    src={cover?.url ?? gDaten?.Gebaeude_Foto ?? ""}
                    alt=""
                    fallback={<i className="ri-home-3-line text-[3rem] text-textLight"></i>}
                    className="h-full w-full"
                />
                <Heading level="h1" className="absolute bottom-3 left-4">
                    {name}
                </Heading>
            </div>

            <section className="grid gap-2 px-6">
                <Heading level="h2" className=" text-text">
                    Gebäudepass
                </Heading>
                <Text variant="body2" className="text-textLight">
                    Der digitale Gebäudepass bildet die Grundlage deiner individuellen Energieberatung mit EnerGuide.
                </Text>
            </section>

            <section className="relative grid grid-cols-2 gap-4 px-2">
                <BigStat
                    iconClass="ri-fire-fill"
                    name="Endenergie"
                    value={
                        kpis?.endenergie?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) ?? "-"
                    }
                    unit="kWh/(m²a)"
                />
                <BigStat
                    iconClass="ri-fire-fill"
                    name="Primärenergie"
                    value={
                        kpis?.primaerenergie?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) ?? "-"
                    }
                    unit="kWh/(m²a)"
                />
                <BigStat
                    iconClass="ri-cloud-fill"
                    name="CO₂"
                    value={
                        kpis?.co2?.toLocaleString("de-DE", {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                        }) ?? "-"
                    }
                    unit="kg/(m²a)"
                />
                <BigStat
                    iconClass="ri-verified-badge-fill"
                    name="Effizienzklasse"
                    value={kpis?.effizienzklasse ?? "-"}
                    unit="nach GEG 24"
                />
            </section>

            <section className="grid gap-2 px-6">
                <Heading level="h2" className=" text-text">
                    Services
                </Heading>
                <Text variant="body2" className="text-textLight">
                    Wähle einen der folgenden Services für dein Gebäude und lass dich von EnerGuide beraten.
                </Text>
            </section>

            <section className="px-2">
                <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
                    {data?.id && consumptionProductPrice && (
                        <CertificateGenerationBox
                            title="Verbrauchsausweis"
                            price={consumptionProductPrice}
                            progress={{ value: 18, max: 18 }}
                            projectId={data.id}
                            type={EnergyCertificateType.Consumption}
                            productId={ProductId.ConsumptionCertificate}
                        />
                    )}
                </GradientBackground>
            </section>

            <section className="px-2">
                <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
                    {data?.id && demandProductPrice && (
                        <CertificateGenerationBox
                            title="Bedarfsausweis"
                            price={demandProductPrice}
                            progress={{ value: 27, max: 27 }}
                            projectId={data.id}
                            type={EnergyCertificateType.Demand}
                            productId={ProductId.DemandCertificate}
                        />
                    )}
                </GradientBackground>
            </section>
        </section>
    )
}
