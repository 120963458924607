import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type MutationParams = {
    data: FormData
}

export function useMutateGDaten(projectId: number | null) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (params: MutationParams) => {
            if (!authHeader || !projectId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/g-datens/update-for?projectId=${projectId}`, {
                    method: "put",
                    body: JSON.stringify({
                        data: Object.fromEntries(params.data),
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gespeichert")
            await queryClient.invalidateQueries({
                queryKey: ["g-datens", projectId],
            })
        },
    })
}
