import { useCreateCertificate } from "@api/certificate/useCreateCertificate"
import { useDraft } from "@api/certificate/useDraft"
import { useLastSentChatId } from "@api/chat/useLastSentChatId"
import { useEtDaten } from "@api/et-daten/useEtDaten"
import { useGDaten } from "@api/g-daten/useGDaten"
import { IMZeile, IMZeileInput } from "@api/mzeile/schema"
import { useDeleteMZeile } from "@api/mzeile/useDeleteMZeile"
import { useMZeiles } from "@api/mzeile/useMZeiles"
import { Button, LoadingDots } from "@components/atoms/buttons"
import { ContentOffset } from "@components/molecules/content-offset"
import { Scrollable } from "@components/molecules/scrollable"
import { Reveal } from "@components/organisms/reveal"
import { EnergyCertificateType, EnergyCertificateVariant } from "@energuide/shared"
import { useDraftIdParam } from "@hooks/useDraftIdParam"
import { useTitlebar } from "@hooks/useTitlebar"
import hljs from "highlight.js/lib/core"
import xml from "highlight.js/lib/languages/xml"
import "highlight.js/styles/github-dark-dimmed.min.css"
import React from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"
import { EnergyBillCard } from "./energy-bill-card"
import { ModernizationCard } from "./modernization-card"
import { ModernizationDialog } from "./modernization-dialog"
import { ReviewCard } from "./review-card"

// Then register the languages you need
hljs.registerLanguage("xml", xml)

export function ReviewDetail() {
    const draftId = useDraftIdParam()
    const { data: draft } = useDraft(draftId)
    const projectId = React.useMemo(() => draft?.project?.id ?? null, [draft])
    const { data: etDaten } = useEtDaten(projectId, { enabled: !!draft?.project })
    const { data: gDaten } = useGDaten(projectId, { enabled: !!draft?.project })
    const { data: lastSentChatIdData } = useLastSentChatId(projectId, { enabled: !!draft?.project })
    const { mutateAsync: regeneratePreview } = useCreateCertificate(
        projectId,
        draft?.type ?? EnergyCertificateType.Demand,
        lastSentChatIdData?.last_sent_message_chat_id ?? 0,
        EnergyCertificateVariant.Draft
    )

    const { data: mZeiles } = useMZeiles(projectId, { enabled: !!draft?.project })
    const deleteMZeileMutation = useDeleteMZeile(projectId)
    const [mZeileOpen, setMZeileOpen] = React.useState(false)
    const [mZeileId, setMZeileId] = React.useState<number | null>(null)
    const [mZeileInputs, setMZeileInputs] = React.useState<IMZeileInput>({
        Bauteil_Anlagenteil: "",
        Massnahmenbeschreibung: "",
        Amortisation: "",
        Spezifische_Kosten: "",
    })

    const onClearMZeileInput = () => {
        setMZeileId(null)
        setMZeileInputs({
            Bauteil_Anlagenteil: "",
            Massnahmenbeschreibung: "",
            Amortisation: "",
            Spezifische_Kosten: "",
        })
    }

    const [regenerating, setRegenerating] = React.useState(false)
    const navigate = useNavigate()

    useTitlebar({
        title: "Vorschau",
        mode: "back",
        backTo: "/authenticated/reviews",
        showContextMenu: false,
        showSearchIcon: false,
    })

    const highlightedXml = React.useMemo(() => {
        return hljs.highlight(draft?.druckappInput ?? "", { language: "xml" }).value
    }, [draft])

    if (!draft) {
        return null
    }

    const regenerate = async () => {
        setRegenerating(true)
        try {
            await regeneratePreview()
            toast.info("Es kann eine Minute dauern, bis die Updates sichtbar sind")
        } finally {
            setRegenerating(false)
        }
    }

    const deleteMZeile = async (id: number) => {
        await deleteMZeileMutation.mutateAsync(id)
    }

    const openUpdateMZeile = async (mZeile: IMZeile) => {
        const { id, ...inputs } = mZeile
        setMZeileId(id)
        setMZeileInputs(inputs)
        setMZeileOpen(true)
    }

    return (
        <ContentOffset
            safeAreas={false}
            offsetAppbar={true}
            className="grid h-dvh grid-rows-[minmax(0,1fr)_auto] overflow-hidden"
        >
            <Scrollable>
                <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-4">
                    <ReviewCard certificate={draft} />

                    {!draft.draft && (
                        <div className="grid place-items-center">
                            <LoadingDots />
                        </div>
                    )}

                    {draft.draft && (
                        <Reveal title="PDF Vorschau">
                            <iframe
                                className="aspect-square w-full"
                                title="PDF Preview"
                                src={draft.draft?.src ?? ""}
                            ></iframe>
                        </Reveal>
                    )}

                    {draft.draft && etDaten && (
                        <Reveal title="Abrechnungen">
                            <EnergyBillCard etDaten={etDaten} />
                        </Reveal>
                    )}

                    {draft.draft && gDaten?.Typenschild_Foto && (
                        <Reveal title="Typenschild">
                            {gDaten.Typenschild_Foto && (
                                <img src={gDaten?.Typenschild_Foto} alt="house image" className="w-full" />
                            )}
                        </Reveal>
                    )}

                    {draft.draft && highlightedXml && (
                        <Reveal title="Input Druckapplikation">
                            <pre
                                className="overflow-x-scroll text-tiny"
                                dangerouslySetInnerHTML={{ __html: highlightedXml }}
                            ></pre>
                        </Reveal>
                    )}

                    {draft.draft?.error && (
                        <Reveal title="Fehler Log">
                            <pre className="overflow-x-scroll text-tiny">{draft?.draft?.error ?? ""}</pre>
                        </Reveal>
                    )}

                    {draft.draft && (
                        <Reveal title="Modernisierungen">
                            <div className="grid gap-4">
                                {mZeiles?.map((mZeile) => (
                                    <ModernizationCard
                                        key={mZeile.id}
                                        mZeile={mZeile}
                                        onDelete={deleteMZeile}
                                        onUpdate={openUpdateMZeile}
                                    />
                                ))}
                                <ModernizationDialog
                                    projectId={draft.project?.id ?? 0}
                                    initialData={mZeileInputs}
                                    id={mZeileId}
                                    open={mZeileOpen}
                                    onOpenChange={(open) => {
                                        setMZeileOpen(open)
                                        if (!open) {
                                            onClearMZeileInput()
                                        }
                                    }}
                                />
                            </div>
                        </Reveal>
                    )}
                </section>
            </Scrollable>

            <div className="mx-auto grid w-full max-w-screen-md content-start gap-2 p-4 sm:grid-cols-2">
                <Button
                    variant="secondary"
                    onClick={regenerate}
                    loading={regenerating}
                    disabled={regenerating || !draft.draft}
                >
                    Neue Vorschau generieren
                </Button>
                <Button
                    variant="primary"
                    onClick={() => navigate(`/authenticated/review-confirmation/${draftId}`)}
                    disabled={regenerating || !draft.draft}
                >
                    Akzeptieren
                </Button>
            </div>
        </ContentOffset>
    )
}
