import { z } from "zod"
import { ACCOUNT_TYPES } from "./constants"

export const AccountTypeIdSchema = z.union([
    z.literal(ACCOUNT_TYPES.Private.id),
    z.literal(ACCOUNT_TYPES.Enterprise.id),
])

export const AccountTypeValueSchema = z.enum([ACCOUNT_TYPES.Private.type, ACCOUNT_TYPES.Enterprise.type])

export const AccountTypeSchema = z.object({
    id: AccountTypeIdSchema,
    type: AccountTypeValueSchema,
    pricesIncludeVAT: z.boolean(),
})

type AccountType = z.infer<typeof AccountTypeSchema>
export { ACCOUNT_TYPES }
export type { AccountType }
