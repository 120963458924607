import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { IMZeileInput } from "./schema"

type IMZeileUpdate = {
    id: number
    inputs: IMZeileInput
}

export function useUpdateMZeile(projectId: number | null) {
    const authHeader = useAuthHeader()

    return useMutation({
        mutationFn: async (params: IMZeileUpdate) => {
            if (!authHeader || !projectId) {
                return null
            }

            const { id, inputs } = params

            return handleFetch(() =>
                fetch(`${apiUrl}/m-zeiles/${id}`, {
                    method: "put",
                    body: JSON.stringify({
                        data: inputs,
                    }),
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json",
                    },
                })
            )
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["mzeiles", projectId],
            })
        },
        onError: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["mzeiles", projectId],
            })
        },
    })
}
