import { useUpdateCertificate } from "@api/certificate/useUpdateCertificate"
import { useDeleteOrder } from "@api/order/deleteOrder"
import { useOrder } from "@api/order/useOrder"
import { useUser } from "@api/user/useUser"
import { Button, LinkButton } from "@components/atoms/buttons"
import { Currency, OrderItemSchema, ProductId } from "@energuide/shared"
import { useMobile } from "@hooks/useMobile"
import { usePaymentChannel } from "@hooks/usePaymentChannel"
import { useScroll } from "@hooks/useScroll"
import { useStripe } from "@libs/stripe-lib"
import { useCallback, useEffect, useState } from "react"
import { toast } from "sonner"

interface Props {
    onNext: () => void
    price: Currency
    projectId: number
    certificateId: number
    previewSrc: string
    productId: ProductId
}

export function CertificateBoxPurchase(props: Props) {
    const { onNext, price, projectId, certificateId, previewSrc, productId } = props
    const [isCreating, setIsCreating] = useState(false)

    const stripeUtils = useStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY).useProductOrder()

    const { data: user } = useUser()
    const { manualScroll } = useScroll("bottom", false, "smooth")
    const { listen, close } = usePaymentChannel()

    useEffect(() => {
        manualScroll("bottom")
    }, [manualScroll])

    const refreshOrderStatus = useCallback(
        async (orderId: number) => {
            const order = await stripeUtils.refreshProductOrderStatus(orderId)
            if (order?.isCaptured || order?.isPaid) {
                onNext()
            }
        },
        [onNext, stripeUtils]
    )

    const { data: order } = useOrder({ projectId, productId })
    const success = useCallback(() => {
        toast.success("Zahlung erfolgreich!")
        close()
    }, [close, onNext])
    useEffect(() => {
        if (order) {
            void refreshOrderStatus(order.id).then(() => {
                onNext()
            })
        }
    }, [order, refreshOrderStatus])

    const deleteOrder = useDeleteOrder()
    const error = useCallback(async () => {
        deleteOrder.mutate({ productId: productId, projectId: projectId })
        toast.error("Zahlung fehlgeschlagen!")
        setIsCreating(false)
        close()
    }, [close, deleteOrder, productId, projectId])

    const listenForPaymentSuccess = useCallback(
        (sessionId: string) => {
            listen((msg) => {
                if (msg.id === sessionId) {
                    if (msg.status === "success") {
                        success()
                    } else if (msg.status === "error") {
                        void error()
                    }
                } else {
                    console.warn("received msg from listen(msg) with different sessionId: ", msg)
                }
            })
        },
        [listen, error, success]
    )

    const isMobile = useMobile()
    const { mutateAsync } = useUpdateCertificate(certificateId)
    const processPayment = useCallback(async () => {
        if (isCreating) {
            return
        } else if (isMobile) {
            toast.error("Zahlung nicht möglich auf mobilen Geräten")
            return
        }

        setIsCreating(true)
        try {
            if (!user?.email) {
                toast.error("Interner Fehler: Benutzer nicht gefunden")
                setIsCreating(false)
                return
            }

            const { stripeSession, fullOrder } = await stripeUtils.orderProduct({
                email: user.email,
                productId: productId,
                projectId,
                certificateId,
            })

            if (!fullOrder) {
                throw new Error("Order not found")
            } else {
                const items = OrderItemSchema.array().parse(fullOrder.orderItems)
                if (items.length === 0) {
                    throw new Error("Order items not found")
                }
                for (let i = 0; i < items.length; i++) {
                    const item = items[i]
                    if (item.productRef === productId) {
                        await mutateAsync({ orderItemRef: item.id })
                        break
                    }
                }
            }

            const sessionId = stripeSession.id
            listenForPaymentSuccess(sessionId)
            setTimeout(() => setIsCreating(false), 5000)

            window.open(`/payment/checkout?sessionId=${sessionId}`, "_blank")
        } catch (error) {
            console.error(error)
            toast.error("Fehler beim Erstellen der Bestellung")
            setIsCreating(false)
        }
    }, [user, projectId, stripeUtils, listenForPaymentSuccess, productId, certificateId, isCreating])

    useEffect(() => {
        if (order) {
            void refreshOrderStatus(order.id)
        }
        return () => {
            window.removeEventListener("focus", success)
            window.removeEventListener("focus", error)
        }
    }, [refreshOrderStatus, success, error, order])

    async function downloadPreview() {
        onNext()
    }

    return (
        <div>
            <p className="mb-4 mt-2 text-textLight">
                Dein vollwertiger Ausweis ohne Wasserzeichen steht bereit. Schließe die Bezahlung ab, um ihn sofort
                herunterzuladen.
            </p>
            <div>
                <div className="flex justify-between">
                    <div className="flex flex-col gap-1">
                        <span className="text-heading2">{price.formatted}</span>
                        <span className="text-small text-textLight">
                            {user?.accountTypeRef?.pricesIncludeVAT ? "inkl. MwSt." : "exkl. MwSt."}
                        </span>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Button
                            loading={isCreating}
                            disabled={isCreating}
                            variant="primary"
                            onClick={processPayment}
                            className="px-8"
                        >
                            Bezahlen
                        </Button>
                        <LinkButton
                            variant="secondary"
                            onClick={downloadPreview}
                            className="px-8"
                            href={previewSrc}
                            isExternal={true}
                        >
                            Vorschau
                        </LinkButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
