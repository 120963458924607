import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { ProjectSchema } from "@api/projects/schema"
import { transform } from "@api/projects/useProjects"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router"

type IUseProject = {
    enabled?: boolean
}

export function useProject(projectId: number | null, params?: IUseProject) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()
    const navigate = useNavigate()

    return useQuery({
        queryKey: ["project", projectId],
        queryFn: async () => {
            try {
                const { data } = await handleFetch(() =>
                    fetch(`${apiUrl}/projects/${projectId}`, {
                        headers: {
                            ...authHeader,
                        },
                    })
                )

                const project = handleParse(() => ProjectSchema.parse(transform(data)))
                return project
            } catch (error) {
                navigate("/authenticated/chat")
            }
        },
        enabled: !!enabled && !!authHeader && !!projectId,
    })
}
