import { useGDaten } from "@api/g-daten/useGDaten"
import { useMutateGDaten } from "@api/g-daten/useMutateGDaten"
import { useMutateProject } from "@api/projects/useMutateProject.ts"
import { useMutateProjectCover } from "@api/projects/useMutateProjectCover.ts"
import { useProject } from "@api/projects/useProject.ts"
import { useRemoveCover } from "@api/projects/useRemoveCover.ts"
import { useRemoveProject } from "@api/projects/useRemoveProject.ts"
import { LoadingDots } from "@components/atoms/buttons/loading-dots.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { Confirmation } from "@components/molecules/confirmation"
import { ContentOffset } from "@components/molecules/content-offset.tsx"
import { ChangeableImage, ChangeableText } from "@components/organisms/changeable-field.tsx"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { useNavigate } from "react-router"

export function ProjectSettings() {
    const projectId = useProjectIdParam()

    const { data, isPending } = useProject(projectId)
    const { name = "---", cover } = data ?? {}

    const { data: gDaten } = useGDaten(projectId)

    const setActiveProject = useAppState((state) => state.setActiveProject)
    const navigate = useNavigate()

    useTitlebar({
        title: name ?? "",
        mode: "back",
        backTo: `/authenticated/chat/${projectId ?? ""}`,
        showContextMenu: true,
        contextMenuProps: {
            showBuildingPass: true,
            showEnergyCertificates: true,
            showProjectSettings: true,
        },
        projectId,
    })

    const projectMutation = useMutateProject(projectId)
    const projectCoverMutation = useMutateProjectCover(projectId)
    const removeCoverMutation = useRemoveCover(projectId)
    const removeProjectMutation = useRemoveProject(projectId)
    const gDatenMutation = useMutateGDaten(projectId)

    if (isPending) {
        return (
            <div className="grid h-full place-content-center">
                <LoadingDots />
            </div>
        )
    }

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-6">
                <section className="grid gap-4">
                    <div className="grid gap-2">
                        <Heading level="h2">Allgemein</Heading>
                        <Text variant="body2" className=" text-textLight">
                            Hier kannst du die grundlegenden Daten des Gebäudes anpassen.
                        </Text>
                    </div>

                    <ChangeableImage
                        value={cover?.url ?? ""}
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Titelbild",
                        }}
                        onMutate={async (data) => projectCoverMutation.mutateAsync({ data })}
                        onDelete={async () => removeCoverMutation.mutateAsync()}
                        data-testid="title-image-container"
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Name",
                        }}
                        inputProps={{
                            name: "name",
                            value: name ?? "",
                        }}
                        onMutate={async (data) => projectMutation.mutateAsync({ data })}
                        data-testid="name-container"
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Straße und Hausnummer",
                            disabled: !gDaten,
                        }}
                        inputProps={{
                            name: "Gebaeudeadresse_Strasse_Nr",
                            value: gDaten?.Gebaeudeadresse_Strasse_Nr ?? "",
                        }}
                        onMutate={async (data) => await gDatenMutation.mutateAsync({ data })}
                        data-testid="street-container"
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "PLZ",
                            disabled: !gDaten,
                        }}
                        inputProps={{
                            name: "Gebaeudeadresse_Postleitzahl",
                            value: gDaten?.Gebaeudeadresse_Postleitzahl ?? "",
                        }}
                        onMutate={async (data) => await gDatenMutation.mutateAsync({ data })}
                        data-testid="zipcode-container"
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Ort",
                            disabled: !gDaten,
                        }}
                        inputProps={{
                            name: "Gebaeudeadresse_Ort",
                            value: gDaten?.Gebaeudeadresse_Ort ?? "",
                        }}
                        onMutate={async (data) => await gDatenMutation.mutateAsync({ data })}
                        data-testid="city-container"
                    />
                </section>

                <section className="grid gap-4">
                    <div className="grid gap-2">
                        <Heading level="h2" className="text-text">
                            Projekt löschen
                        </Heading>
                        <Text variant="body2" className=" text-textLight">
                            Hier kannst du dein Projekt und alle damit verbundenen Daten löschen.
                        </Text>
                    </div>
                    <Confirmation
                        trigger="Projekt löschen"
                        title="Projekt löschen"
                        description="Willst du dieses Projekt wirklich unwiderruflich löschen?"
                        cancel="Lieber nicht"
                        action="Projekt löschen"
                        onConfirm={async () => {
                            await removeProjectMutation.mutateAsync()
                            setActiveProject(null)
                            navigate("/authenticated/chat")
                        }}
                    />
                </section>
            </section>
        </ContentOffset>
    )
}
