import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { UsageTrackingSchema } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

const useUsageTracker = () => {
    const authHeader = useAuthHeader()

    //TODO: invalidate the query when the user sends messages, creates certificate, or creates project
    return useQuery({
        queryKey: ["user-usage-tracker"],
        queryFn: async () => {
            if (!authHeader) {
                return null
            }

            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/usage-tracking`, {
                    method: "GET",
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            const parsed = handleParse(() => UsageTrackingSchema.parse(response))
            return parsed
        },
        enabled: !!authHeader,
    })
}

export { useUsageTracker }
