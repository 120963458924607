import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { ISubscription, SubscriptionSchema } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { SubscriptionPricingResponse } from "./schema"

const usePrivateSubscriptions = () => {
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["subscription-options"],
        queryFn: async (): Promise<Array<ISubscription & { disabled: undefined | string }> | null> => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/subscriptions/private`, {
                    method: "GET",
                    headers: {
                        ...authHeader,
                    },
                })

                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response || !response.data) {
                return null
            }

            const { data } = response
            const parsed = handleParse(() => SubscriptionSchema.array().parse(data))
            const result = parsed.map((sub) => ({
                ...sub,
                disabled: undefined,
            }))
            return result
        },
        enabled: !!authHeader,
    })
}

export { usePrivateSubscriptions }
export type { SubscriptionPricingResponse }
