import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { DebugDemandResponse } from "./schema"

type IUseDebugDemand = {
    enabled?: boolean
}

export function useDebugDemand(projectId: number | null, params?: IUseDebugDemand) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["debug", "demand", projectId],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/energy-certificate/debug/demand?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const debug = handleParse(() => DebugDemandResponse.parse(data.attributes))
            return debug
        },
        enabled: !!enabled && !!authHeader && !!projectId,
    })
}
