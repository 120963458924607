import { Heading, Text } from "@components/atoms/typography"
import { ContentOffset } from "@components/molecules/content-offset"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import { useTitlebar } from "@hooks/useTitlebar"

export function DataProtection() {
    const projectId = useProjectIdParam()

    useTitlebar({
        title: "",
        mode: "back",
        backTo: `/authenticated/chat/${projectId ?? ""}`,
        showContextMenu: false,
    })

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-6">
                <div className="grid gap-1">
                    <Heading level="h2">Datenschutz</Heading>
                    <Text variant="small" className="text-textLight">
                        Stand: 27.08.2024
                    </Text>
                </div>

                <section className="grid gap-2">
                    <Heading level="h4">Anleitung zum Löschen eines Accounts in der App</Heading>
                    <Text variant="body1" className="text-textLight">
                        Wenn Sie Ihren Account in unserer App löschen möchten, folgen Sie bitte den untenstehenden
                        Schritten. Beachten Sie, dass das Löschen des Accounts unwiderruflich ist und alle Ihre Daten
                        dauerhaft gelöscht werden.
                    </Text>

                    <Text variant="body1" className="text-textLight">
                        Schritt 1: Navigieren Sie zur Sidebar Im Web: Sobald Sie sich in der Web-Version der App
                        eingeloggt haben, sehen Sie auf der linken Seite die Sidebar. Hier finden Sie alle wichtigen
                        Navigationsoptionen, darunter auch den Zugriff auf Ihr Konto. In der nativen App: In der nativen
                        App (auf mobilen Geräten) sehen Sie in der normalen Chat-Ansicht oben links ein Hamburger-Menü
                        (drei horizontale Linien). Klicken Sie darauf, um die Sidebar "Projekte" zu öffnen.
                    </Text>

                    <Text variant="body1" className="text-textLight">
                        Schritt 2: Öffnen Sie die Kontoübersicht Klicken Sie in der Sidebar auf „Konto“, um zur
                        Kontoübersicht zu gelangen. Dort sehen Sie Ihre Account-Informationen. Um die Löschoptionen zu
                        erreichen, klicken Sie auf den Stift-Button unten rechts neben Ihren Account-Informationen.
                    </Text>

                    <Text variant="body1" className="text-textLight">
                        Schritt 3: Account unwiderruflich löschen Nachdem Sie auf den Stift-Button geklickt haben, sehen
                        Sie die erweiterten Einstellungen. In diesem Bereich finden Sie den Button „Account
                        unwiderruflich löschen“. Klicken Sie auf diesen Button, um den Löschvorgang zu starten. Bitte
                        beachten Sie, dass dieser Vorgang nicht rückgängig gemacht werden kann.
                    </Text>

                    <Text variant="body1" className="text-textLight">
                        Bestätigen Sie den Löschvorgang Nachdem Sie auf „Account unwiderruflich löschen“ geklickt haben,
                        werden Sie aufgefordert, Ihre Entscheidung zu bestätigen. Sobald Sie dies getan haben, wird Ihr
                        Account und alle zugehörigen Daten dauerhaft gelöscht.
                    </Text>

                    <Text variant="body1" className="text-textLight">
                        Bitte beachten Sie, dass das Löschen des Accounts endgültig ist und nicht rückgängig gemacht
                        werden kann. Falls Sie sich nicht sicher sind, können Sie vor dem Löschen eine Sicherung Ihrer
                        Daten vornehmen.
                    </Text>

                    <Text variant="body1" className="text-textLight">
                        Diese Anleitung soll Ihnen helfen, Ihren Account sicher und einfach zu löschen. Falls Sie
                        weitere Fragen haben, wenden Sie sich gerne an unseren Support.
                    </Text>
                </section>
            </section>
        </ContentOffset>
    )
}
