import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useUserKey } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useRemoveUserAvatar(userId: number | null) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !userId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/users/${userId}`, {
                    method: "put",
                    body: JSON.stringify({
                        avatar: {
                            set: [],
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gelöscht")
            await queryClient.invalidateQueries({
                queryKey: useUserKey,
            })
        },
    })
}
