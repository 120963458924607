import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { EnergyCertificateCollectionSchema, IEnergyCertificateCollection } from "@energuide/shared"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useUpdateCertificate(certificateId: number) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (certificate: Partial<IEnergyCertificateCollection>) => {
            if (!authHeader) {
                throw new Error("User is not authenticated")
            }

            const response = await handleFetch(() =>
                fetch(`${apiUrl}/energy-certificates/${certificateId}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        data: certificate,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
            if (!response) {
                throw new Error("No response from server")
            }

            return EnergyCertificateCollectionSchema.parse(response)
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["certificate", certificateId],
            })
            await queryClient.invalidateQueries({
                queryKey: ["draft"],
            })
        },
        onError: (error: any) => {
            console.error("Error updating certificate:", error)
            toast.error("Fehler beim Aktualisieren des Zertifikats")
        },
    })
}
