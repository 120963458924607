import { useCertificate } from "@api/certificate/useCertificate"
import { useGDaten } from "@api/g-daten/useGDaten"
import { useKpis } from "@api/kpis/useKpis"
import { useProject } from "@api/projects/useProject.ts"
import { Image } from "@components/atoms/image.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { BigStat } from "@components/molecules/big-stat.tsx"
import { EnergyCertificateType } from "@energuide/shared"
import { useProjectIdParam } from "@hooks/useProjectIdParam"
import { useTitlebar } from "@hooks/useTitlebar.ts"

export function BuildingPass() {
    const projectId = useProjectIdParam()
    const { data } = useProject(projectId)
    const { name = "---", cover } = data ?? {}
    const { data: gDaten } = useGDaten(projectId)
    const { data: certificate } = useCertificate(projectId, EnergyCertificateType.Demand)
    const { data: kpis } = useKpis(projectId, {
        // only run the KPIs query if a certificate was already created
        enabled: !!certificate && !(certificate.draft?.error || certificate.final?.error),
    })

    useTitlebar({
        title: name ?? "",
        mode: "back",
        backTo: `/authenticated/chat/${projectId}`,
        showContextMenu: true,
        contextMenuProps: {
            showBuildingPass: true,
            showEnergyCertificates: true,
            showProjectSettings: true,
        },
        projectId,
    })

    return (
        <section className="mx-auto grid max-w-screen-md content-start gap-10 pb-4">
            <div className="relative aspect-square object-cover lg:aspect-video">
                <Image
                    src={cover?.url ?? gDaten?.Gebaeude_Foto ?? ""}
                    alt=""
                    fallback={<i className="ri-home-3-line text-[3rem] text-textLight"></i>}
                    className="h-full w-full"
                />
                <Heading level="h1" className="absolute bottom-3 left-4">
                    {name}
                </Heading>
            </div>

            <section className="grid gap-2 px-6">
                <Heading level="h2" className=" text-text">
                    Gebäudepass
                </Heading>
                <Text variant="body2" className="text-textLight">
                    Der digitale Gebäudepass bildet die Grundlage deiner individuellen Energieberatung mit EnerGuide.
                </Text>
            </section>

            <section className="relative grid grid-cols-2 gap-4 px-2">
                <BigStat
                    iconClass="ri-fire-fill"
                    name="Endenergie"
                    value={
                        kpis?.endenergie?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) ?? "-"
                    }
                    unit="kWh/(m²a)"
                    data-testid="end-energy-container"
                />
                <BigStat
                    iconClass="ri-fire-fill"
                    name="Primärenergie"
                    value={
                        kpis?.primaerenergie?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) ?? "-"
                    }
                    unit="kWh/(m²a)"
                    data-testid="primary-energy-container"
                />
                <BigStat
                    iconClass="ri-cloud-fill"
                    name="CO₂"
                    value={
                        kpis?.co2?.toLocaleString("de-DE", {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                        }) ?? "-"
                    }
                    unit="kg/(m²a)"
                    data-testid="emissions-container"
                />
                <BigStat
                    iconClass="ri-verified-badge-fill"
                    name="Effizienzklasse"
                    value={kpis?.effizienzklasse ?? "-"}
                    unit="nach GEG 24"
                    data-testid="efficiency-container"
                />
            </section>
        </section>
    )
}
