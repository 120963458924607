import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"
import { IMZeileInput } from "./schema"

export function useCreateMZeile(projectId: number | null) {
    const authHeader = useAuthHeader()

    return useMutation({
        mutationFn: async (params: IMZeileInput) => {
            if (!authHeader || !projectId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/m-zeiles?projectId=${projectId}`, {
                    method: "post",
                    body: JSON.stringify({
                        data: params,
                    }),
                    headers: {
                        ...authHeader,
                        "Content-Type": "application/json",
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Modernisierung erstellt")
            await queryClient.invalidateQueries({
                queryKey: ["mzeiles", projectId],
            })
        },
        onError: async () => {
            toast.error("Erstellen fehlgeschlagen")
            await queryClient.invalidateQueries({
                queryKey: ["mzeiles", projectId],
            })
        },
    })
}
